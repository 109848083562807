.Header
{
  position: relative;
  width: 100%;
  color: #fff;
  background: #333;
  z-index: 1;
}
.Header > .Inner
{
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  padding: 0 10px;
}
.Header h1
{
  font-size: 1.3rem;
  white-space: nowrap;
  margin: 0;
}
.Header h1 a
{
  display: block;
  padding: 14px 10px;
  width: fit-content;
}
.Header button
{
  font-size: 1.8rem;
  background-color: transparent;
  color: inherit;
  border: none;
  padding: 2px 6px;
  opacity: .8;
  text-shadow: inherit;
  cursor: pointer;
  outline: none;
}
.Header button:hover,
.Header button:focus,
.Header button:active
{
  opacity: 1;
}
.Header a[href]
{
  color: inherit;
}
.Header small
{
  text-align: center;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  font-size: .6rem;
  padding: 10px;
  opacity: .5;
}
@media (min-width: 900px)
{
  .Header
  {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto 1fr;
    width: fit-content;
    height: 100%;
  }
  .Header h1
  {
    padding: 10px 0;
  }
  .Header button
  {
    display: none;
  }
}
