.HeaderMenuNav
{
  position: absolute;
  top: 0;
  left: 100%;
  height: 100vh;
  padding: 40px 10px 10px;
  box-sizing: border-box;
  background-color: #333;
  z-index: 2;
}
.HeaderMenuNav ul
{
  font-weight: bold;
  padding: 0;
  margin: 0;
  list-style: none;
}
.HeaderMenuNav li
{
  transition: background-color .2s;
}
.HeaderMenuNav li
{
  border-bottom: 1px solid rgba(255, 255, 255, .05);
}
.HeaderMenuNav li a
{
  display: block;
  padding: 15px 20px;
  opacity: .8;
}
.HeaderMenuNav li a:hover,
.HeaderMenuNav li a:focus,
.HeaderMenuNav li a:not([href])
{
  opacity: 1;
}
.HeaderMenuNav li a:not([href])
{
  cursor: default;
}
@media (max-width: 899px)
{
  .HeaderMenuNav
  {
    width: 400px;
  }
}
@media (max-width: 499px)
{
  .HeaderMenuNav
  {
    width: 80vw;
  }
}
@media (min-width: 900px)
{
  .HeaderMenuNav
  {
    width: 100%;
    height: auto;
    position: relative;
    left: 0;
    background-color: transparent;
  }
  .HeaderMenuNav ul
  {
    width: fit-content;
    padding: 0 10px;
  }
  .HeaderMenuNav li a
  {
    padding: 10px;
  }
}
