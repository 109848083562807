.ContactsLink
{
  padding: 10px 0;
  display: flex;
  align-items: center;
  width: fit-content;
}
.ContactsLink .Icon
{
  font-size: 1.4rem;
  margin-right: 15px;
}
