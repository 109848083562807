.AppContent
{
  position: relative;
  left: 0;
  height: 100%;
  transition: transform .3s;
  display: grid;
  grid-template-rows: auto 1fr;
  touch-action: none;
}
@media (max-width: 899px)
{
  .AppContent.open .Backdrop
  {
    display: block;
  }
}
