.AlbumNavBlock
{
  position: absolute;
  right: 0;
  bottom: 0;
  margin: 20px 10px;
  font-size: 1.6rem;
  display: flex;
  align-items: center;
}
.AlbumNavBlock .AlbumNavCounter
{
  opacity: .7;
  width: 100px;
  text-align: center;
  user-select: none;
}
.AlbumNavBlock button
{
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0;
  opacity: .7;
  color: inherit;
  font-size: 3rem;
  text-shadow: inherit;
}
@media (min-width: 900px)
{
  .AlbumNavBlock button
  {
    cursor: pointer;
  }
  .AlbumNavBlock button:hover,
  .AlbumNavBlock button:focus-visible
  {
    opacity: 1;
  }
}
