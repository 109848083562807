.Main
{
  display: grid;
  height: 100%;
  overflow: auto;
  box-sizing: border-box;
}
.Main > .Content
{
  box-sizing: border-box;
  padding: 0 20px;
  background-color: #fff;
  width: 100%;
}
.Main .Loading
{
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  opacity: .5;
  color: #fff;
}
.Main hr
{
  border: none;
  border-bottom: 1px dotted rgba(0, 0, 0, .1);
  margin: 30px 0;
}
@media (min-width: 900px)
{
  .Main
  {
    height: 100vh;
  }
  .Main > .Content
  {
    padding: 0 30px;
  }
}
