.PhotosMain
{
  width: 100%;
  height: 100%;
  overflow: hidden;
  color: #fff;
  position: relative;
}
.PhotosMain > .Inner
{
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 1);
}
.ZoomButton
{
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 7px;
}
