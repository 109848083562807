@import 'icons/css/portfolio.css';

html
{
  height: 100%;
}
body
{
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  background-color: #000;
}
body
{
  height: 100%;
}
a[href]
{
  color: #00a;
  text-decoration: none;
}
a[href]:hover,
a[href]:focus
{
  color: #00f;
}
.Icon::before
{
  margin: 0;
}
.appear
{
  transition: opacity .3s, transform .3s;
}
[aria-busy=true] .appear
{
  transform: translateY(20px);
  opacity: 0;
}
