.PhotoImg
{
  display: inline-block;
  background: center center / contain no-repeat;
  width: 100%;
  height: 100%;
  transition: transform .3s, background-size .3s, background-position .3s;
  position: absolute;
}
@media (min-width: 900px)
{
  .PhotoImg
  {
    background-size: contain;
  }
}
