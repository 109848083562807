.PhotosList
{
  height: 100%;
  position: relative;
  white-space: nowrap;
  cursor: grab;
  touch-action: none;
}
.PhotosList:active
{
  cursor: grabbing;
}
