.PostArticle
{
  background-color: #fff;
  padding: 20px;
  margin-bottom: 20px;
}
.PostArticle h3,
.PostArticle p
{
  max-width: 900px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.PostArticle h3
{
  margin-top: 10px;
}
.PostArticle time
{
  font-size: .8rem;
}
.PostArticle p
{
  font-size: .9rem;
}
.PostArticle img
{
  max-width: 100%;
  max-height: 382px;
  vertical-align: top;
  margin-bottom: 5px;
}
.PostArticle img:not(:last-child)
{
  margin-right: 5px;
}
/*
@media (min-width: 900px)
{
  .PostArticle
  {
    max-width: 900px;
  }
}
*/
