.App
{
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  transition: margin-left .3s;
}
.App.homepage .ZoomButton
{
  display: none;
}
@media (min-width: 900px)
{
  .App .AppContent
  {
    grid-template-rows: auto;
    grid-template-columns: auto 1fr;
  }
  .App.homepage .AppContent
  {
    display: block;
  }
  .App.homepage .Header
  {
    position: absolute;
    background: linear-gradient(to right, rgba(0, 0, 0, 0.4) 5%, rgba(0, 0, 0, 0) 100%) transparent;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 1);
  }
  .App.homepage .HeaderMenuNav
  {
    background: transparent;
  }
  .App.homepage .SocialBlock,
  .App.homepage small
  {
    display: none;
  }
  .App.homepage li a[href]
  {
    opacity: 1;
  }
}
