.DocumentsMain a[href]
{
  display: flex;
  align-items: center;
  padding: 15px 0;
  font-size: 1rem;
  width: fit-content;
}
.DocumentsMain a[href] .Icon_file-pdf
{
  font-size: 1.5rem;
  margin-right: 10px;
}
