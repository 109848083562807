.AlbumCardLink
{
  display: block;
  padding-top: 55%;
  background: center center / cover no-repeat;
  position: relative;
  outline: none;
}
.AlbumCardLink h2
{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  font-size: 1.2rem;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
}
@media (min-width: 900px)
{
  .AlbumCardLink:hover .AlbumCardTitle,
  .AlbumCardLink:focus .AlbumCardTitle
  {
    opacity: 1;
  }
}
