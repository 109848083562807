.AlbumCardTitle
{
  position: absolute;
  width: 100%;
  bottom: 0;
  padding: 10px;
  box-sizing: border-box;
  font-weight: bold;
  color: #fff;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 1);
  display: flex;
  align-items: flex-end;
  font-size: .9rem;
  background: linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
}
@media (min-width: 900px)
{
  .AlbumCardTitle
  {
    top: 0;
    bottom: 0;
    background: rgba(255, 255, 255, .8);
    opacity: 0;
    transition: opacity .3s;
    text-shadow: none;
    color: #000;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 20px;
  }
}
